<template>
  <div>
    <p class="bg-red-500 text-black p-4">Hello World</p>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>